import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jljeau43fmcbp6m3jptntafdxm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45v1signin_45global from "/opt/build/repo/middleware/01.v1signin.global.ts";
import _02_45auth_45global from "/opt/build/repo/middleware/02.auth.global.ts";
import _03_45profile_45global from "/opt/build/repo/middleware/03.profile.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__jljeau43fmcbp6m3jptntafdxm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45v1signin_45global,
  _02_45auth_45global,
  _03_45profile_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-merchant": () => import("/opt/build/repo/middleware/admin-merchant.ts"),
  admin: () => import("/opt/build/repo/middleware/admin.ts"),
  guest: () => import("/opt/build/repo/middleware/guest.ts")
}