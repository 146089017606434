import { z } from 'zod';
import type { Message } from '~/models/Localization';

/**
 * When using outside of the normal Nuxt structure .e.g. a /models/ file,
 * the Nuxt auto-import might not working. Please import the file manually
 * if an error occurs.
 */
export const zStringReq = (message?: Message) =>
	z.string().trim().min(1, { message: message ?? 'validation.error.required' });
